import dynamic from 'next/dynamic'
const Skeleton = dynamic(() => import('react-loading-skeleton'), { ssr: false })


function SkeletonCell() {

    return (
        <div style={{ fontSize: 18, lineHeight: 1.5, width: "100%"}}>
            <h1><Skeleton /></h1>
            <Skeleton count={4} />
        </div>
    )
}

export default SkeletonCell;