import React, { Component } from 'react'
import SuperNavBar from '../components/SuperNavBar.js'
import Context from '../components/Context'
import Head from 'next/head'
import axios from 'axios';
import SkeletonCell from '../components/SkeletonCell'
import Link from 'next/link'
import SignUpModal from '../components/SignUpModal'
const api = process.env.API;
import { firebase, auth } from '../firebase'

class Index extends Component {
  static contextType = Context;

  state = {
    groups: [],
    showSignUpModal: false
  }

  componentDidMount = async () => {
    console.log("mount");
    await this.context.actions.getCurrentUser(() => {
      if(this.context.currentUser) {
        //get user groups
        axios.get(api + '/groups/user/' + this.context.currentUser._id)
        .then((res) => {
          if(res) {
            console.log("groups");
            console.log(res.data);
            this.setState({
              groups: res.data
            })
          }
        })
        .catch((err) => {
          if(err) {
            alert(err);
          }
        })
      }
    });
  }

  toggleSignUpModal = () => {
    this.setState({
      showSignUpModal: !this.state.showSignUpModal
    })
  }

  render() {
    var skeleton = [];
    for(var i = 0; i < 5; i++) {
      skeleton.push(
        <SkeletonCell />
      );
    }
    if(this.state.loadingPage) {
      return (
        <div className="loader"></div>
      )
    }
    else {
      if(auth.currentUser) {
        return (
          <React.Fragment>
            <Head>
              <style>{'body { background-color: #202225 }'}</style>
            </Head>
            {this.context.currentUser ?
              <div className="container">
                <SuperNavBar />
                <div className="groupList">
                  <div className="groupHeaderText">
                    <h1 style={{marginRight: "20px", display: "inline-block"}}>My Realms</h1>
                    <Link href="/invite/pending">
                      <a className="pending">Pending Realm Requests 📩</a>
                    </Link>
                  </div>
                  {this.state.groups.map((group, key) => {
                    return (
                      <Link href={`/g/${group.domain}`}>
                        <div className="groupCell">
                          <h3>{group.name}</h3>
                          <p>{group.description}</p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
              :
              null
            }
          </React.Fragment>
        )
      }
      else if(!this.state.loadingPage) {
        return (
          <div className="homepage container">
            <Head>
              <style>{'body { background-color: #202225 }'}</style>
            </Head>
            <div className="homepageWrapper">
              {
                this.state.showSignUpModal ?
                  <SignUpModal toggle={this.toggleSignUpModal} />
                  :
                  null
              }
              <div>
                <h1>realms</h1>
                <p>anonymous and toxicity free spaces to talk</p>
              </div>
              <div className="buttons">
                <button className="signup" onClick={this.toggleSignUpModal}>Enter Realms</button>
              </div>
            </div>
          </div>
        )
      }
    }
  }
};

export default Index;